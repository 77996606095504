:root {
    --accentColor: #E42320;
    --primaryTextColor: #333333;
    --backgroundColor: #F2F2F7;
}

button {
    all: unset;
}

button:focus {
    outline: revert;
}

body {
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    color: var(--primaryTextColor);
    border: 0;
    margin: 0;
}

table {
    top: 50pt;
    text-indent: 0;
    border-collapse: collapse;
    border-color: inherit;
}

td {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

th {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.eventRow {
    border-bottom: 1px solid #e5e7eb;
    transition: background-color 0.2s ease-in-out;
    cursor: pointer;
}

.eventRow:hover {
    background-color: #f7fafc;
}

.eventTitle {
    max-width: 32rem;
    font-weight: bold;
    font-size: 1.25rem;
}

.monthHeaderContainer {
    position: -webkit-sticky;
    position: sticky;
    top: 118pt;
    z-index: 1000;
    width: 100%;
    height: 16pt;
    text-align: left;
}

.monthHeader {
    background-color: var(--accentColor);
    width: 128pt;
    color: white;
    padding: 4pt 16pt 4pt 16pt;
    border-radius: 4pt;
    text-transform: capitalize;
    text-align: center;
}

.header {
    position: -webkit-sticky;
    position: sticky;
    top: 68pt;
    z-index: 10000;
    height: 50pt;
    color: var(--primaryTextColor);
    background-color: white;
    border-top: none !important;
    border-bottom: none !important;
    box-shadow: inset 0 -0.5pt 0 var(--primaryTextColor);
}

.chipsContainer {
    display: flex;
    flex-wrap: wrap;
}

.chip {
    background-color: #333333bd;
    color: white;
    font-size: 0.875rem;
    margin-right: 0.25rem;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    border-radius: 4pt;
}

.placeChip {
    margin-right: 0.25rem;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
}

#EventsTable {
    width: 100%;
    text-align: left;
}

.eventDate {
    color: var(--accentColor);
}

.inset {
    padding: 1rem;
}

.fullScreenBackground {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vh;
    height: 100vh;
}

.titleText {
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: bold;
}

.bodyText {
    font-size: 1.125rem;
    line-height: 1.75rem;
}

.plusButton {
    color: var(--accentColor);
    font-size: 2rem;
    font-weight: lighter;
}

.highlight {
    background-color: #e42320d2;
}
:root {
  --accentColor: #e42320;
  --primaryTextColor: #333;
  --backgroundColor: #f2f2f7;
}

button {
  all: unset;
}

button:focus {
  outline: revert;
}

body {
  color: var(--primaryTextColor);
  border: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
}

table {
  text-indent: 0;
  border-collapse: collapse;
  border-color: inherit;
  top: 50pt;
}

td, th {
  padding: 1rem 1.5rem;
}

.eventRow {
  cursor: pointer;
  border-bottom: 1px solid #e5e7eb;
  transition: background-color .2s ease-in-out;
}

.eventRow:hover {
  background-color: #f7fafc;
}

.eventTitle {
  max-width: 32rem;
  font-size: 1.25rem;
  font-weight: bold;
}

.monthHeaderContainer {
  z-index: 1000;
  text-align: left;
  width: 100%;
  height: 16pt;
  position: sticky;
  top: 118pt;
}

.monthHeader {
  background-color: var(--accentColor);
  color: #fff;
  text-transform: capitalize;
  text-align: center;
  border-radius: 4pt;
  width: 128pt;
  padding: 4pt 16pt;
}

.header {
  z-index: 10000;
  height: 50pt;
  color: var(--primaryTextColor);
  box-shadow: inset 0 -.5pt 0 var(--primaryTextColor);
  background-color: #fff;
  position: sticky;
  top: 68pt;
  border-top: none !important;
  border-bottom: none !important;
}

.chipsContainer {
  flex-wrap: wrap;
  display: flex;
}

.chip {
  color: #fff;
  background-color: #333333bd;
  border-radius: 4pt;
  margin-top: .25rem;
  margin-bottom: .25rem;
  margin-right: .25rem;
  padding: .25rem .5rem;
  font-size: .875rem;
}

.placeChip {
  margin-top: .25rem;
  margin-bottom: .25rem;
  margin-right: .25rem;
  padding-top: .25rem;
  padding-bottom: .25rem;
}

#EventsTable {
  text-align: left;
  width: 100%;
}

.eventDate {
  color: var(--accentColor);
}

.inset {
  padding: 1rem;
}

.fullScreenBackground {
  width: 100vh;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
}

.titleText {
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 2rem;
}

.bodyText {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.plusButton {
  color: var(--accentColor);
  font-size: 2rem;
  font-weight: lighter;
}

.highlight {
  background-color: #e42320d2;
}
/*# sourceMappingURL=index.9bf0fae7.css.map */
